.nav_link {
  display: inline;
  align-items: center;
  text-decoration: none;
  color: #CCEBFF;
  font-size: 14px;
  line-height: 20px;
  padding: 12px;
  border-radius: 4px;
  transition: all .3s ease-in-out;
}

.nav_link:hover {
  /* transform: translateY(-3px); */
  box-shadow: 0 5px 10px rgba(0,0,0,.3);
  cursor: pointer;
}

.nav_link:active {
  transform: translateY(0);
  box-shadow: 0 3px 5px rgba(0,0,0,.4);
}

.box-user{
  position: absolute;
  right: 0; 
}

.menu-drop{
  z-index: 100;
  width: 100%;
padding: 8px 16px;
cursor: pointer;
transition: all .3s ease-in-out;
color: #CCEBFF;
font-size: 14px;
line-height: 20px;
position: relative;
}

.menu-drop ul{
background-color: #00426B;
border-bottom-left-radius: 4px;
border-bottom-right-radius: 4px;

}

.menu-drop span{
  color:#CCEBFF;
}

#menu-list-grow a{
  text-decoration: none;
  color:#CCEBFF;
}


.menu__user-dropdown{
  top: 54px;
}

#menu-list-grow li{
  text-decoration: none;
  color:#CCEBFF;
}