@import '../../icts-theme/src/utils/Variables.scss';
@import '../../icts-icon/src/utils/variables.scss';

.form-radio {
  display: flex;
  position: relative;

  &__input {
    display: none;
  }

  &__label {
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    color: $color-gray-5;
    font-weight: $font-weight-base;
    margin-left: $spacing-xxs;
    position: relative;
    top: -2px;
  }

  &::before {
    content: '';
    border: 1px solid $color-gray-5;
    width: 16px;
    height: 16px;
    border-radius: 50%;
  }

  &.is-checked,
  &.is-disabled.is-checked {
    &::after {
      top: 2px;
      left: 2px;
      width: 12px;
      content: '';
      height: 12px;
      position: absolute;
      border-radius: 50%;
    }
  }

  &.is-checked {
    &::before {
      border-color: $color-primary;
    }

    &::after {
      background-color: $color-primary;
    }
  }

  &.is-disabled {
    &::before {
      border-color: $color-gray-3;
    }

    &.is-checked {
      &::after {
        background-color: $color-gray-3;
      }
    }
  }

  &:hover:not(.is-disabled) {
    &::before {
      border-color: $color-primary;
    }
  }

  &:focus:not(.is-disabled) {
    &::before {
      border-color: $color-blue-3;
    }
  }

  &:not(:first-child) {
    margin-top: 16px;
  }
}
