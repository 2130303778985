@import '../../icts-theme/src/utils/Variables.scss';

.blank-state {
  width: 100%;
  height: 100%;

  &__content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  &__icon::before {
    color: #13bf1b;
  }

  &__label {
    font-size: $font-size-md;
    line-height: $line-height-md;
    color: #350066;
    text-align: center;
    max-width: 278px;
    margin-top: $spacing-md;
  }

  &--table {
    padding: 160px 0;
  }
}

