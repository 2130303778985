@import '../../icts-theme/src/utils/Variables.scss';

.card {
  padding: $spacing-lg;
  border-radius: 5px;
  box-shadow: 0 2px 15px 0 $color-gray-3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__heading {
    color: $color-primary;
    font-size: $font-size-lg;
    line-height: $line-height-lg;
    font-weight: $font-weight-semiBold;
    margin: 0;
    margin-bottom: $spacing-md;
  }

  @media screen and (max-width: 992px) {
    padding: $spacing-md;
  }

  @media screen and (max-width: 576px) {
    padding: $spacing-sm;
  }
}
