@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
  //margin-right: -$gutter / 2;
  //margin-left: -$gutter / 2;
}

@mixin row-cols($count) {
  & > * {
    flex: 0 0 100% / $count;
    max-width: 100% / $count;
  }
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}

@mixin make-grid-columns(
  $columns: $grid-columns,
  $gutter: $grid-gutter-width,
  $breakpoints: $grid-breakpoints
) {
  %grid-column {
      position: relative;
      width: 100%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
  }

  @each $breakpoint in map-keys($breakpoints) {
      $infix: breakpoint-infix($breakpoint, $breakpoints);

      @for $i from 1 through $columns {
          .col#{$infix}-#{$i} {
              @extend %grid-column;
          }
      }
      .col#{$infix},
      .col#{$infix}-auto {
          @extend %grid-column;
      }

      @include media-breakpoint-up($breakpoint, $breakpoints) {
          .col#{$infix} {
              flex-basis: 0;
              flex-grow: 1;
              max-width: 100%;
          }
          @for $i from 1 through $grid-row-columns {
              .row-cols#{$infix}-#{$i} {
                  @include row-cols($i);
              }
          }
          .col#{$infix}-auto {
              flex: 0 0 auto;
              width: auto;
              max-width: 100%;
          }
          @for $i from 1 through $columns {
              .col#{$infix}-#{$i} {
                @include make-col($i, $columns);
              }
          }
          @for $i from 0 through ($columns - 1) {
              @if not ($infix == "" and $i == 0) {
                  .offset#{$infix}-#{$i} {
                      @include make-col-offset($i, $columns);
                  }
              }
          }
      }
  }
}
