.form-textarea {
  width: 100%;

  .form-input {

    &__control {
      position: relative;
    }

    &__target {
      -webkit-appearance: none;
      appearance: none;
      padding: 12px;
    }
  }

  @media screen and(max-width: 768px) {
    width: 50%;
  }

  @media screen and (max-width: 576px) {
    width: 100%;

    &__target {
      padding: 8px;
    }
  }
}
