@import '../../icts-theme/src/utils/Variables.scss';

.button {
  border: 1px solid transparent;
  box-sizing: border-box;
  text-transform: uppercase;
  font-size: $font-size-xs;
  line-height: $line-height-xs;
  border-radius: $border-radius-base;
  letter-spacing: .2px;
  transition: all .3s ease-in-out;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:focus {
    outline: 0;
  }

  &.is-disabled,
  &:disabled {
    cursor: not-allowed;
  }

  &__primary {
    background-color: $color-primary;
    color: $color-white;

    &:hover:not(.is-disabled, :disabled) {
      background-color: $color-primary-1;
      box-shadow: 0 2px 15px $color-gray-2;
      transition: all .3s ease-in-out;
    }

    &:focus {
      background-color: $color-primary;
      border-color: $color-blue-3;
      box-shadow: 0 0 3px rgba(0, 118, 222, .5);
    }

    &.is-active {
      background-color: $color-primary-2;
    }

    &.is-disabled,
    &:disabled {
      background-color: $color-gray-3;
    }
    .icon {
      &::before {
        color: $color-white;
      }
    }
  }

  &__secondary {
    border-color: $color-primary;
    color: $color-primary;

    &:hover:not(.is-disabled, :disabled) {
      border-color: $color-primary-1;
      box-shadow: 0 0 6px 1px $color-gray-2;
      transition: all .3s ease-in-out;
    }

    &:focus {
      border-color: $color-blue-3;
      box-shadow: 0 0 3px rgba(0, 118, 222, .5);
    }

    &.is-active {
      border-color: $color-primary-2;
      color: $color-primary-2;
      .icon {
        &::before {
          color: $color-primary-2;
        }
      }
    }

    &.is-disabled,
    &:disabled {
      color: $color-gray-3;
      border-color: $color-gray-3;
      box-shadow: 0 2px 15px $color-gray-2;
      .icon {
        &::before {
          color: $color-gray-3;
        }
      }
    }
    .icon {
      &::before {
        color: $color-primary;
      }
    }
  }

  &--lg {
    padding: $spacing-sm;
    .button--icon {
      margin-right: $spacing-sm;
    }
  }

  &--md {
    padding: $spacing-xs;
    .button--icon {
      margin-right: $spacing-xs;
    }
  }

  &--sm {
    padding: $spacing-xxs;
    .button--icon {
      margin-right: $spacing-xxs;
    }
  }
}
