@import '../../icts-theme/src/utils/Variables.scss';

.modal {
  top: 0;
  left: 0;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: none;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: rgba($color-secondary, .25);
  &__wrapper {
    width: 100%;
    border-radius: 4px;
    background-color: #FFF;
    box-shadow: 0 2px 4px rgba(155, 173, 198, .2);
    position: relative;
    &--sm {
      max-width: 340px;
      padding: $spacing-sm;
    }
    &--md {
      max-width: 440px;
    }
    &--lg {
      max-width: 640px;
    }
    &--md,
    &--lg {
      padding: $spacing-md;
    }
  }
  &__header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-gray-4;
    padding-bottom: 4px;

    .close {
      background-color: transparent;
      border: none;
      cursor: pointer;
      &:focus {
        outline: 0;
      }
    }
  }
  &__body {
    margin-top: $spacing-sm;
  }
  &--show {
    display: flex;
  }
}
