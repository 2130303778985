@import '../../icts-theme/src/utils/Variables.scss';

.menu {
  background-color: #420080;
  height: 84px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  &__brand {
    height: 100%;
    background-color: #350066;
    display: flex;
    padding: $spacing-md;
  }

  &__company {
    height: 32px;
    margin-left: $spacing-md;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  &__right {
    margin-left: auto;
    display: flex;
    align-items: center;
    height: 100%;
  }

  &__nav {
    margin: 0;
    padding: 0 32px 0 98px;
    display: flex;
    align-items: center;

    &-item {
      position: relative;

      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color-blue-2;
    font-size: $font-size-sm;
    line-height: $line-height-sm;
    padding: $spacing-xs;
    border-radius: 4px;
    transition: all .3s ease-in-out;

    &-icon {
      margin-right: $spacing-xs;

      &::before {
        color: $color-blue-2;
      }
    }

    &-icon-dropdown {
      margin-left: $spacing-xs;
      cursor: pointer;

      &::before {
        color: $color-blue-2;
      }
    }

    &-dropdown {
      position: absolute;
      top: 66px;
      width: 100%;
      min-height: 84px;
      margin: 0;
      padding: $spacing-xxs 0;
      right: 0;
      background-color: $color-secondary;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover,
    &:focus {
      background-color: #0D4A70;
    }

    &--active {
      background-color: rgba($color-white, .15);
      cursor: pointer;

     &:hover,
      &:focus {
        background-color: rgba($color-white, .15);
      }
    }
  }

  &__box {
    height: 100%;
    border-left: 1px solid #13bf1b;
    display: flex;
    align-items: center;
    padding: 0 $spacing-sm;
    transition: all .3s ease-in-out;
    min-width: 84px;
    position: relative;

    &:hover,
    &:focus {
      background-color: #c6f7cb;
      opacity: 0.8;

    }
  }

  &__search {
    padding: $spacing-sm;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: 1px solid $color-blue-2;
    height: 48px;

    &-icon::before {
      color: $color-blue-2;
    }

    &--show {
      background-color: $color-gray-3;
      border-color: $color-gray-3;
    }

    &--show &-icon::before {
      color: $color-secondary;
    }

    &-target {
      margin: 0 $spacing-sm;
      padding: 0;
      border: 0;
      font-weight: $font-weight-semiBold;
      line-height: $line-height-base;
      color: $color-gray-6;
      outline: 0;
      background-color: transparent;

      &::placeholder {
        color: $color-gray-4;
      }
    }

    &-action {
      padding: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      outline: 0;
    }
  }

  &__language {

    &-select {
      color: #8AA8BB;
      font-size: $font-size-sm;
      line-height: $font-size-md;
      text-transform: uppercase;
      padding: $spacing-sm;
      cursor: pointer;
    }

    &-options {
      display: none;
      position: absolute;
      top: 84px;
      width: 100%;
      min-height: 84px;
      margin: 0;
      padding: $spacing-xxs 0;
      right: 0;
      background-color: $color-secondary;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      &--show {
        display: flex;
        flex-direction: column;
      }
    }

    &-option {
      padding: $spacing-xxs $spacing-sm;
      list-style: none;
      cursor: pointer;
      transition: all .3s ease-in-out;
      color: $color-blue-2;
      font-size: $font-size-sm;
      line-height: $font-size-md;
      text-transform: uppercase;

      &:hover {
        background-color: rgba($color-white, .15);
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;

    &-avatar {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: $spacing-xxs;
      cursor: pointer;
    }

    &-action {
      padding: 0;
      border: 0;
      background-color: transparent;
      cursor: pointer;
      outline: 0;
    }

    &-icon::before {
      color: $color-blue-2;
    }

    &-dropdown {
      position: absolute;
      top: 84px;
      width: 180px;
      min-height: 84px;
      margin: 0;
      padding: $spacing-xxs 0;
      right: 0;
      background-color: #ffffff;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      box-shadow: 0 2px 15px #E3E8F1;
    }
  }

  &__item {
    width: 100%;
    padding: $spacing-xxs $spacing-sm;
    cursor: pointer;
    transition: all .3s ease-in-out;
    color: #ffffff;
    font-size: $font-size-sm;
    line-height: $font-size-md;
    position: relative;

    &-link {
      text-decoration: none;
      color: #350066;
      display: flex;
      justify-content: space-between;
      align-items: center;
      }
      a:hover{font-size: 16pt};

    &-icon {
      transform: rotate(-90deg);

      &::before {
        color: $color-blue-2;
      }
    }

    &-dropdown {
      position: absolute;
      background-color: $color-secondary;
      left: 100%;
      top: 0;
      width: 100%;
      padding: $spacing-xxs 0;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &:hover {
      background-color: rgba($color-white, .15);
    }
  }

  &__hamburger {
    display: none;
    flex-direction: column;
    padding: $spacing-sm;
    cursor: pointer;
    z-index: 12;

    .line {
      background-color: $color-white;
      width: 24px;
      height: 4px;
      border-radius: 2px;
      position: relative;
      transition: all .4s cubic-bezier(0.445, 0.05, 0.55, 0.95);

      &-2 {
        margin: 4px 0;
        left: 0;
        opacity: 1;
      }
    }

    &--close .line {
      &:first-child {
        transform: rotate(45deg);
        top: 5px;
      }

      &:last-child {
        transform: rotate(-45deg);
        top: -3px;
      }

      &:nth-child(2) {
        left: 16px;
        margin: 0;
        opacity: 0;
      }
    }
  }

  /*
  ** Responsive
  */

  @media screen and (max-width: 1810px) {
    &__box {
      justify-content: flex-end;
    }

    &__search--show {
      position: absolute;
    }
  }

  @media screen and (max-width: 1350px) {
    &__nav {
      padding: 0 $spacing-sm 0 $spacing-lg;

      &-item:not(:first-child) {
        margin-left: $spacing-xxs;
      }
    }
  }

  @media screen and (max-width: 1240px) {
    &__hamburger {
      display: flex;
      margin-left: auto;
    }

    &__body {
      transform: translateX(100%);
      opacity: 0;
      flex-direction: column;
      justify-content: flex-start;
      position: absolute;
      right: 0;
      top: 0;
      width: 0;
      height: 100vh;
      background-color: $color-secondary;
      transition: all .8s cubic-bezier(0.455, 0.03, 0.515, 0.955);
      padding: 56px $spacing-md $spacing-md $spacing-md;
      display: none;

      &--show {
        width: 325px;
        opacity: 1;
        transform: translateX(0);
        z-index: 9;
        display: flex;
      }
    }

    &__nav {
      flex-direction: column;
      padding: 0;

      &-item {
        margin: $spacing-xxs 0 0 0;
      }
    }

    &__right {
      margin: $spacing-lg 0;
      height: auto;
      flex-wrap: wrap;
    }

    &__link {

      &-dropdown {
        position: relative;
        top: 0;
      }
    }

    &__item {

      &-dropdown {
        position: relative;
        top: 0;
        left: 0;
        width: 100%;
        margin-top: 16px;
      }
    }

    &__box {
      border: none;

      &:first-child {
        width: 100%;
      }

      &:not(:last-child) {
        margin-left: auto;
      }
    }

    &__search {
      padding: 8px;
      height: 32px;
    }

    &__search--show {
      position: relative;
    }

    &__language {

      &-select {
        border: 1px solid #0D4A70;
        border-radius: 5px;
        padding: 8px 20px;
      }

      &-options {
        top: 66px;
      }
    }
  }

}
