@import "./reset";
@import "../packages/icts-theme/src/utils/Variables.scss";

.page-content {
  min-height: calc(100vh - 204px);
  padding-top: $spacing-xl;
}

.powerbi-embedded-container {
    background: #EAEAEA;
    color: #6E6E6E;
    font: Lighter 40px Segoe UI;    
    height: calc(0.5625 * 65vw); /* 16:9 aspect ratio */
    min-width: 800px;
    text-align: center;
    width: 95%;
    margin: 0 12px 0px 35px;
}


@media only screen and (max-width: 1333.33px) {
  #reportContainer {
  height: calc(0.5625 * 870px); /* 16:9 aspect ratio */
}
}

.u-text-uppercase {
  text-transform: uppercase;
}

.u-mv-lg {
  margin: $spacing-xs 0;
}


html, body {
  height: 100vh;
}

#root {
  min-height: 100%;
  position: relative;
}

.footer {
  position: absolute;
}

.dropdown.dropdown--bottom {
  z-index: 2;
}

.button--lg {
  float: right;
  margin-left: 14px;
}

.form-input {
  width: 100% ;
}


.card-div{
  width: 100%;
}

.button-container{
  display: flex;
  justify-content: left;
  margin-left: 35px;
}

.div-header-dashboard{
  .form-input{
  width: 100% !important;
  }
  .dropdown{
    width: 100%;
  }
} 