@import '../../icts-theme/src/utils/Variables.scss';

.form-select {
  cursor: pointer;

  &__arrow {
    cursor: pointer;
  }

  > .dropdown--bottom {
    top: 100%;
  }

  > .dropdown--top {
    top: auto;
    bottom: 100%;
  }

  &__options {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -4px;
  }

  &__option {
    padding: 4px;
    display: flex;
    font-weight: 400;
    margin-right: 4px;
    margin-bottom: 4px;
    color: $color-white;
    font-size: $font-size-xs;
    line-height: $line-height-xs;
    background-color: $color-secondary;
  }

  &__icon {
    cursor: pointer;
    padding-left: 4px;
  }

  &__icon::before {
    color: $color-white;
  }
}
