@import '../../icts-theme/src/utils/Variables.scss';

.calendar {
  background: $color-white;
  border: 1px solid #DAE3EE !important;

  .react-datepicker {
    &__triangle {
      left: 50%;
      margin-left: -9px;
      position: absolute;
      border: 9px solid transparent;
      height: 0;
      z-index: 1;
      width: 1px;
      top: 0;
      margin-top: -9px;
      border-top: none;
      border-bottom-color: #DAE3EE;

      &::before {
        position: absolute;
        border: 8px solid transparent;
        height: 0;
        width: 1px;
        content: '';
        border-width: 8px;
        left: -8px;
        z-index: 4;
        border-top: none;
        top: 2px;
        border-bottom-color: $color-white;
      }
    }

    &__header {
      border: 0;
      background: $color-white;
    }

    &__month-container {
      float: inherit;
    }

    // &__navigation {
    //   display: none;
    // }

    &__month {
      margin: 12px 12px 12px;
    }

    &__day,
    &__day-name {
      width: 32px;
      height: 32px;
      font-size: 12px;
      font-weight: 400;
      line-height: 32px;
      color: $color-gray-5;

      &--selected {
        color: $color-white;
        background-color: $color-secondary;
      }
    }

    &__day-name {
      font-size: 14px;
    }
  }

  &__header-selects {
    display: flex;
    margin-bottom: 8px;

    .form-input {
      flex: 1;
      margin: 0 12px;
    }
  }

  &__title {
    font-weight: 600;
    line-height: 20px;
    color: $color-gray-6;
  }

  &__footer {
    display: flex;
    padding: 0 12px 12px 0;
    justify-content: flex-end;

    .button {
      margin-left: 16px;
    }
  }
}
