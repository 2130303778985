@import "../../../packages/icts-theme/src/utils/Variables.scss";

.dashboard-list {
  &__item:link,
  &__item:visited {
    text-decoration: none;
    display: inline-block;
    width: 100%;
    user-select: none;
    background-color: $color-primary;
    color: #ffffff;
    text-transform: uppercase;
    padding: $spacing-lg;
    margin-bottom: $spacing-xs;
    border-radius: 2px;
    transition: all .2s;
    height: 128px;
    line-height: 110%;
    

    &:hover {
      transform: translateY(-3px);
      box-shadow: 0 5px 10px rgba(0,0,0,.3);
      cursor: pointer;
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 3px 5px rgba(0,0,0,.4);
    }
  }
}

.div-botton {
  padding-top: 10px;
  height: 50px;
  
}



