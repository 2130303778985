@import '../../icts-theme/src/utils/Variables.scss';

.modal {
  &__alert {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--title,
    &--description {
      text-align: left;
      width: 100%;
    }

    &--title {
      margin: $spacing-md 0 $spacing-xxs 0;
    }

    .icon-alerta::before {
      color: $color-warning;
    }

    .icon-erro::before {
      color: $color-error;
    }

    .alert-buttons {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: $spacing-sm;
      width: 100%;

      .button:first-child {
        margin-right: $spacing-sm;
      }
    }
  }
}
